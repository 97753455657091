import React, { useState } from 'react'
import styles from './SearchCategory.module.css'
import Header from 'components/Header/Header'
import SelectPageCat from 'components/SelectPageCat/SelectPageCat'
import { useTranslation } from 'react-i18next'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import ButtonSubmit from 'components/ButtonSubmit/ButtonSubmit'
import ProfileCard from 'components/ProfileCard/ProfileCard'



const SearchCategory = () => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const { data: authReducerState } = useAuthReducer()

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [searchResult, setSearchResult] = useState('')

    const [category1, setCategory1] = useState(null)
    const [category2, setCategory2] = useState(null)
    const [category3, setCategory3] = useState(null)

    const [showOption2, setShowOption2] = useState(false)
    const [showOption3, setShowOption3] = useState(false)


    // NAVIGATION STATE
    const goBack = () => navigate(-1)


    const handleAddOption = () => {
        if (!showOption2) {
            setShowOption2(true)
        }
        if (showOption2 && !showOption3) {
            setShowOption3(true)
        }
    }


    const fetchData = async () => {
        try {
            setIsLoading(true)
            const form = new FormData()
            form.append('category_id_1', category1)
            if (category2) {
                form.append('category_id_2', category2)
            }
            if (category3) {
                form.append('category_id_3', category3)
            }
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/search/search_page_by_category`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    ...accessToken,
                },
            });
            console.log('Response search by category:', response?.data)
            setIsLoading(false)
            if (response.status === 200) {
                if (response?.data?.success) {
                    setData(response?.data?.search_page)
                    if (response?.data?.search_page.length !== 0) {
                        setSearchResult(`${response?.data?.search_page?.length} ${authReducerState?.user?.user_lang_session.toLowerCase() === 'en' ? 'page' : response?.data?.search_page?.length == 1 ? 'page' : 'pages'}`)
                    } else {
                        setSearchResult(`${authReducerState?.user?.user_lang_session.toLowerCase() === 'en' ? 'No result' : 'Aucun résultat'}`)
                    }
                }
            }

        } catch (error) {
            setIsLoading(false)
            console.log('Fetch data error:', error)
        }
    }


    return (
        <div className={styles.screen}>
            <Header title={t('search_by_category')} goBack={goBack} />
            <div className={styles.container}>
                <SelectPageCat
                    value={category1}
                    setValue={setCategory1}
                    style={{
                        marginBottom: '20px'
                    }}
                />
                <SelectPageCat
                    value={category2}
                    setValue={setCategory2}
                    style={{
                        marginBottom: '20px'
                    }}
                />
                <SelectPageCat
                    value={category3}
                    setValue={setCategory3}
                    style={{
                        marginBottom: '20px'
                    }}
                />

                <div className={styles.containerButton}>
                    <ButtonSubmit
                        onClick={fetchData}
                        value='Rechercher'
                        isLoading={isLoading}
                        style={{
                            width: '50%',
                            color: 'white'
                        }}
                    />
                </div>

                <p className={styles.textResult}>{searchResult}</p>

            </div>

            {data?.map((page) => {
                return (
                    <ProfileCard user={page} />
                )
            })}
        </div>
    )
}

export default SearchCategory