import React, { useState } from 'react'
import styles from './UserCard.module.css'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { color } from 'hooks/Utils/color'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { api } from 'api/api'

const UserCard = ({
    user_id,
    user,
    onRefresh = () => null
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authReducerState } = useAuthReducer()

    const [youFollow, setYouFollow] = useState(true)


    const unblockBuddy = async () => {
        const body = { user_id: user?.user_id }
        try {
          const request = await api(
            'api/profile/blocked',
            'POST',
            body,
            accessToken,
          )
          const response = await request.json()
          if (request.ok && request.status === 200) {
            if (response.success) {
                onRefresh()
            }
          }
        } catch ({ message }) {
          throw new Error(message)
        }
      }

    return (
        <div className={styles.container}>
            <div className={styles.userContainer}>
                <ProfileCard user={user} pictureSize={60} />
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    onClick={unblockBuddy}
                    variant={"outlined"}
                    style={{
                        backgroundColor:color.white
                    }}>
                    <span
                        style={{
                            color: color.primary,
                            fontSize: '12px',
                            fontWeight: '600'
                        }}>
                        {t('unlock')}
                    </span>
                </Button>
            </div>
        </div>
    )
}

export default UserCard