import React, { useEffect, useState } from 'react'
import styles from './Calendar.module.css'
import Header from 'components/Header/Header'
import Calendar from 'react-calendar';
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks';
import { api } from 'api/api';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import EventCard from '../Events/EventList/EventCard/EventCard';
import { Spinner } from 'react-bootstrap';
import { MdEvent } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';


const MyCalendar = () => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const [events, setEvents] = useState([]);
    const [tileContent, setTileContent] = useState([]);

    const [hasMorepage, setHasMorePage] = useState(1);
    const [isEmpty, setIsEmpty] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const [dateSelected, setDateSelected] = useState(new Date());

    const goBack = () => navigate(-1)

    const fetchEvents = async (dateStart, dateEnd) => {
        try {
            setIsFetching(true)
            // Get params
            // page: integer
            // start_at: Date au format (jj-mm-aaaa)
            // end_at: Date au format (jj-mm-aaaa)
            const request = await api(`api/events?start_at=${dateStart}&end_at=${dateEnd}`, 'GET', {}, accessToken)
            const response = await request.json()
            // console.log('Fetch events response:', response?.data?.data)
            setIsFetching(false)
            if (request?.ok && request?.status === 200) {
                if (response?.data?.next_page_url) {
                    setHasMorePage(true)
                } else {
                    setHasMorePage(false)
                }
                if (response?.data?.data?.length === 0) {
                    setIsEmpty(true)
                } else {
                    setIsEmpty(false)
                }
                setEvents(response?.data?.data)
            }
        } catch (error) {
            setIsFetching(false)
            console.error('Fetch requests error:', error);
        }
    }

    const handleDateChange = (date) => {
        const dateFormated = dayjs(date).format('DD-MM-YYYY')
        setDateSelected(dateFormated)
        fetchEvents(dateFormated, dateFormated)
    }

    return (
        <div className={styles.page}>
            <Header title={t('myCalendar')} goBack={goBack} />
            <div className={styles.container}>
                <Calendar
                    value={dateSelected}
                    onChange={handleDateChange}
                    // tileContent={({ date, view }) => {
                    //     if (view === 'month') {
                    //         const event = events.find((event) => event.date.getTime() === date.getTime());
                    //         if (event) {
                    //             return <p>{event.title}</p>;
                    //         }
                    //     }
                    //     return null;
                    // }}
                    className={styles.calendar}
                />

                <div className={styles.eventContainer}>
                    <h4 className={styles.titleEventContainer}>
                        {t('events')}
                    </h4>
                    {isFetching &&
                        <div style={{ paddingTop: '16vh', textAlign: 'center' }}>
                            <Spinner />
                        </div>
                    }
                    {isEmpty && !isFetching &&
                        <div className={styles.emptyContainer}>
                            <div className={styles.emptyContent}>
                                <MdEvent className={styles.iconEmptyContent} />
                            </div>
                            <h5 className={styles.textEmptyContent}>
                                {t('noEventAvailable')}
                            </h5>
                        </div>
                    }
                    {!isFetching && events?.map((event, index) => {
                        return (
                            <div key={index?.toString()}>
                                <EventCard event={event} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default MyCalendar