import React from 'react'
import styles from './AddAccount.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import LoginForm from 'components/LoginForm/LoginForm'
import Header from 'components/Header/Header'
import TextInput from 'components/TextInput/TextInput'
import ButtonSubmit from 'components/ButtonSubmit/ButtonSubmit'
import { useState } from 'react'
import { color } from 'hooks/Utils/color'
import { api } from 'api/api'
import { ValidateEmail } from 'hooks/Utils/parsing'
import { useAccountReducer, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { Alert } from '@mui/material'

const AddAccount = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: authReducerState } = useAuthReducer()
  const { state: accountReducerState, dispatch: acccountReducerDispatch } = useAccountReducer()


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [error, setError] = useState('');
  const [IPAddress, setIPAddress] = useState('192.168.1.1');

  // NAVIGATION HANDLER
  const goBack = () => navigate(-1)


  // CHECK FORM
  const checkForm = async (e) => {
    e.preventDefault();
    if (!ValidateEmail(email.trim())) {
      setError(t('invalid_email'))
      return
    }
    if (password.length < 8) {
      setError(t('invalid_password'))
      return
    }
    setError('')
    handleSubmit()
  }


  // LOGIN HANDLER 
  const handleSubmit = async () => {
    try {
      setError('')
      setIsLoading(true)
      const body = {
        user_email: email,
        user_password: password,
        user_ip: IPAddress,
      }
      const request = await api('api/login', 'POST', body)
      const response = await request.json()
      // console.log(response)
      setIsLoading(false)
      if (request.ok && request.status === 200) {

        if (accountReducerState?.accounts?.some(account => account.user.user_id !== response?.user?.user_id)) {

          const account = {
            authorization: `${response?.type} ${response?.access_token}`,
            user: response?.user
          }

          acccountReducerDispatch({
            type: 'ADD_ACCOUNT',
            payload: { ...account, owner: authReducerState?.user?.user_id }
          })

          goBack()

        } else {
          setError(t('this_account_has_already_been_added'))
        }

      } else {
        setError(t('incorrect_email_address_or_password'))
      }
    } catch (error) {
      setError(t('an_error_occurred_please_try_again_later'));
      setIsLoading(false)
      console.warn(error.message);
    }
  };


  return (
    <div className={styles.container}>
      <Header title={t('add_an_account')} goBack={goBack} />
      <div id='content' className={styles.content}>
        <form onSubmit={checkForm}>

          <div className={styles.containerLogo}>
            <img src={require('assets/app/icon_socialhub.png')}
              className={styles.logo}
              alt='image'
            />
          </div>
          <h3 className={styles.title}>{t('login')}</h3>

          {/* <span style={{ color: 'red' }}>{error}</span> */}

          {error &&
            <Alert severity="error" style={{ marginTop: '5%' }}>{error}</Alert>
          }

          <TextInput
            value={email}
            setValue={setEmail}
            type='text'
            placeholder={t('email')}
            style={{
              width: '100%',
              marginTop: '5%',
              marginBottom: '5%'
            }}
          />

          <TextInput
            value={password}
            setValue={setPassword}
            type='password'
            placeholder={t('password')}
            style={{
              width: '100%',
              marginBottom: '8%'
            }}
          />

          <ButtonSubmit
            value={t('login')}
            isLoading={isLoading}
            loadBoostrapColor={'light'}
            style={{
              width: '100%',
              color: 'white',
              marginBottom: '5%',
              backgroundColor: color.primary
            }}
          />

        </form>
      </div>
    </div>
  )
}

export default AddAccount