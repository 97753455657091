import React, { useEffect } from 'react'
import styles from './CreatePage.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import LoginForm from 'components/LoginForm/LoginForm'
import Header from 'components/Header/Header'
import TextInput from 'components/TextInput/TextInput'
import ButtonSubmit from 'components/ButtonSubmit/ButtonSubmit'
import { useState } from 'react'
import { color } from 'hooks/Utils/color'
import { api } from 'api/api'
import { ValidateEmail } from 'hooks/Utils/parsing'
import { useAccessToken, useAccountReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { Alert, TextField, Button, Box, MenuItem } from '@mui/material'
import SelectMultiPageCat from 'components/SelectMultiPageCat/SelectMultiPageCat'


const CreatePage = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const accessToken = useAccessToken()

  // REDUCER STATE
  // const { state: pageState, dispatch: pageDispatch } = usePageReducer()

  const [countries, setCountries] = useState([])


  // FORM STATE
  const [pageName, setPageName] = useState('')
  const [username, setUsername] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userCountry, setUserCountry] = useState('')
  const [profDescription, setProfDescription] = useState('')
  const [profPhone, setProfPhone] = useState('')
  const [profSiteWeb, setProfWebSite] = useState('')
  const [profPicture, setProfPicture] = useState('')
  const [profCover, setProfCover] = useState('')
  const [category, setCategory] = useState([])



  // ERROR STATE
  const [errors, setErrors] = useState({})

  // LOADING STATE
  const [isLoading, setIsLoading] = useState(false)

  // Regular expression pattern for email validation
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;


  // NAVIGATION HANDLER
  const goBack = () => navigate(-1)

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const request = await api('api/get-countries', 'GET', {})
        const response = await request.json()
        // console.log('Fetch countries:', response) 
        if (request.ok && request.status === 200) {
          const countries = response.countries.map(c => ({
            label: c.French_Name,
            value: c.Sort_Order,
            key: c.ITU_T_Telephone_Code,
          }))
          setCountries(countries)
        }
      } catch (error) {
        // fetchCountries()
        console.log('Erreur', error.message)
      }
    }
    fetchCountries()
  }, [])


  const handleCreatePage = async (e) => {
    try {
      e.preventDefault();
      if (!pageName?.trim()) {
        setErrors({ name: 'Entrez le nom de la page.' })
        return
      }
      if (!username?.trim()) {
        setErrors({ username: 'Entrez le nom de la page.' })
        return
      }
      if (!profDescription?.trim()) {
        setErrors({ description: 'Entrez le nom de la page.' })
        return
      }
      if (!userEmail?.trim() || !emailRegex.test(userEmail)) {
        setErrors({ email: 'Entrez une adresse mail valide.' })
        return
      }

      setErrors({})
      setIsLoading(true)
      const form = {
        user_name: pageName,
        user_username: username,
        user_email: userEmail,
        user_pays: userCountry,
        prof_description: profDescription,
        prof_phone: profPhone,
        prof_site_web: profSiteWeb,
        categories: category,
      }
      const request = await api('api/pages', 'POST', form, accessToken)
      const response = await request.json()
      console.log('Create new page response:', response)
      setIsLoading(false)
      if (request.status === 200) {
        if (response.success) {
          setErrors({ success: 'Ce pseudonyme a déjà été utilisé.' })
          setTimeout(() => {
            goBack()
          }, 1500);
        }
      } else {
        if (response?.errors?.user_username) {
          setErrors({ request: 'Ce pseudonyme a déjà été utilisé.' })
          return
        }
        if (response?.errors?.user_username) {
          setErrors({ request: response?.errors?.user_username[0] })
          return
        }
        if (response?.errors?.user_email) {
          setErrors({ request: response?.errors?.user_email[0] })
          return
        }
        if (response?.errors?.user_pays) {
          setErrors({ request: response?.errors?.user_pays[0] })
          return
        }
      }
    } catch (error) {
      // setIsLoading(false)
      // Alert.alert(t('login:noInternetConnection'), t('login:messageNoInternetConnection'))
      console.error('Create new page error:', error?.message);
    }
  }




  return (
    <div className={styles.container}>
      <Header title={t('create_a_page')} goBack={goBack} />
      <div className={styles.content}>

        <p className={styles.description}>
          {t('create_a_page_description')}
        </p>

        <Box
          component="form"
          onSubmit={handleCreatePage}
          sx={{
            '& .MuiTextField-root': {
              mx: 3,
              my: 2,
              width: { xs: '89%', md: '89%' }
            },
          }}
          noValidate
          autoComplete="off">

          <div>

            <TextField
              required
              label={t('pageName')}
              type='name'
              value={pageName || ''}
              onChange={(event) => {
                setPageName(event.target.value)
                const username = event?.target?.value?.toLowerCase()?.replaceAll(' ', '_')?.trim()
                setUsername(username)
              }}
              error={errors?.name}
              helperText={errors?.name}
              size='small'
            />

            <TextField
              required
              label={t('pageNickname')}
              value={username || ''}
              onChange={(event) => {
                const username = event?.target?.value?.toLowerCase()?.replaceAll(' ', '_')?.trim()
                setUsername(username)
              }}
              error={errors?.username}
              helperText={errors?.username}
              size='small'
            />

            <TextField
              required
              label={t('descriptionOfThePage')}
              multiline
              maxRows={10}
              value={profDescription || ''}
              onChange={(event) => setProfDescription(event.target.value)}
              error={errors?.description}
              helperText={errors?.description}
              size='small'
            />


            <SelectMultiPageCat
              categories={category}
              setCategories={setCategory}
            />


            <TextField
              required
              label={t('emailOfThePage')}
              type='email'
              value={userEmail || ''}
              onChange={(event) => setUserEmail(event.target.value)}
              error={errors?.email}
              helperText={errors?.email}
              size='small'
            />

            <TextField
              label={t('phone')}
              type='phone'
              value={profPhone || ''}
              onChange={(event) => setProfPhone(event.target.value)}
              size='small'
            />

            <TextField
              select
              label={t('country')}
              defaultValue=""
              size='small'
              SelectProps={{
                native: true,
              }}
              value={userCountry || ''}
              onChange={(event) => setUserCountry(event.target.value)}>
              {countries?.sort((a, b) =>
                a.label.localeCompare(b.label)
              )?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option?.label}
                </option>
              ))}
            </TextField>

            <TextField
              label={t('webSite')}
              value={profSiteWeb || ''}
              onChange={(event) => setProfWebSite(event.target.value)}
              size='small'
            />

            {errors?.request &&
              <Alert severity="error" className={styles.textError}>{errors?.request}</Alert>
            }

            {errors?.success &&
              <Alert severity="success" className={styles.textError}>{errors?.success}</Alert>
            }

            <div className={styles.buttonBox}>
              <ButtonSubmit
                value={t('createPage')}
                isLoading={isLoading}
                style={{
                  width: '50%',
                  color: 'white',
                  marginTop: '10px'
                }}
              />
            </div>


          </div>
        </Box>

      </div>
    </div>
  )
}

export default CreatePage