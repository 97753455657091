import React from 'react'
import styles from './UserCard.module.css'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { color } from 'hooks/Utils/color'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { api } from 'api/api'

const UserCard = ({
    user_id,
    user,
    onRefresh = () => null
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authReducerState } = useAuthReducer()


    const approveBuddy = async () => {
        if (user?.user_id) {
            const body = { user_id: user?.user_id }
            try {
                const request = await api(
                    'api/profile/approve-request-buddy',
                    'POST',
                    body,
                    accessToken,
                )
                const response = await request.json()
                // console.log(response, 'response of approve buddy')
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        onRefresh()
                    }
                }
            } catch ({ message }) {
                throw new Error(message)
            }
        }
    }

    const refuseBuddy = async () => {
        if (user?.user_id) {
            const body = { user_id: user?.user_id }
            try {
                const request = await api(
                    'api/profile/refuse-buddy-invitation',
                    'POST',
                    body,
                    accessToken,
                )
                const response = await request.json()
                // console.log(response, 'response of refuse buddy ')
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        onRefresh()
                    }
                }
            } catch ({ message }) {
                throw new Error(message)
            }
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.userContainer}>
                <ProfileCard user={user} pictureSize={60} />
            </div>
            {authReducerState?.user?.user_id === user_id &&
                <div className={styles.buttonContainer}>
                    <Button onClick={approveBuddy} className={styles.button} style={{ backgroundColor: color.primary }}>
                        <span className={styles.textButton}>
                            {t('accept')}
                        </span>
                    </Button>
                    <Button onClick={refuseBuddy} variant='outlined' className={styles.button} style={{ backgroundColor: color.white }}>
                        <span className={styles.textOutlinedButton} >
                            {t('delete')}
                        </span>
                    </Button>
                </div>
            }
        </div>
    )
}

export default UserCard