import React, { useEffect, useState } from 'react'
import styles from './ModeratorCard.module.css'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import ButtonSubmit from 'components/ButtonSubmit/ButtonSubmit'
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { api } from 'api/api'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField } from '@mui/material'
import { Spinner } from 'react-bootstrap'
import { color } from 'hooks/Utils/color'


const ModeratorCard = ({
    page_id,
    account
}) => {


    const { t, i18n } = useTranslation()
    const isEnLang = i18n.language === 'en'
    const accessToken = useAccessToken()


    const [isSended, setIsSended] = useState(false)

    useEffect(() => {
      setIsSended(account?.page_moderation_requests?.length !== 0)
    }, [account])
    

    const [role, setRole] = useState('OWNER')
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const [isVisible, setIsVisible] = useState(false)

    const roleOptions = [
        { value: 'OWNER', label_en: 'Owner', label_fr: 'Propriétaire' },
        { value: 'ADMIN', label_en: 'Administrator', label_fr: 'Adminitrateur' },
        { value: 'MODERATOR', label_en: 'Moderator', label_fr: 'Moderateur' },
    ]


    const handleOpenDialog = () => {
        setIsVisible(true)
    }

    const handleCloseDialog = () => {
        setIsVisible(false)
    }


    const handleConfirm = async () => {
        try {
            if (!role.trim()) {
                setErrors({ role: t('selectARole') })
                return
            }
            setIsLoading(true)
            const body = {
                up_page_id: page_id,
                up_user_id: account?.user_id,
                up_role: role //required|string [OWNER, ADMIN, MODERATOR]
            }
            const request = await api('api/page_moderation/send_request', 'POST', body, accessToken)
            const response = await request.json()
            // console.log('Send invite admin page response:', response) 
            setIsLoading(false)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    setIsSended(true)
                    handleCloseDialog()
                } else {
                    setErrors({ request: `${t('profilEdit:sorry')}, ${t('profilParam:inviteErrorMessage')}` })
                }
            }
        } catch (error) {
            setIsLoading(false)
            setErrors({ request: `${t('noInternetConnection')}, ${t('messageNoInternetConnection')}` })
            console.log('Send invite admin page response:', error)
        }
    }


    return (
        <div className={styles.container}>
            <ProfileCard user={account} />
            {isSended ?
                <Button variant="contained" disabled style={{ width: '80px' }}>
                    <span style={{ fontSize: '13px' }}>{t('invited')}</span>
                </Button>
                :
                <ButtonSubmit
                    onClick={handleOpenDialog}
                    value={t('invite')}
                    isLoading={false}
                    style={{
                        width: '80px',
                        fontWeight: 600,
                        color: 'white'
                    }}
                />
            }


            {/* RENDER DIALOG */}
            <Dialog open={isVisible} onClose={handleCloseDialog}>
                <DialogTitle> {account?.user_surname || ''} {account?.user_name || ''} </DialogTitle>
                <DialogContent>
                    <DialogContentText mb={2}>
                        {t('inviteConfirmMessage')}
                    </DialogContentText>
                    {errors?.request &&
                        <Alert severity="error" style={{ marginBottom: '15px' }}>{errors.request}</Alert>
                    }
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Role"
                        type="email"
                        fullWidth
                        variant="outlined"
                        select
                        error={errors?.role}
                        helperText={errors?.role}
                        value={role || ''}
                        onChange={(event, value) => setRole(event.target.value)}>
                        {roleOptions.map((option) => (
                            <MenuItem key={option.value} value={option?.value}>
                                {isEnLang ? option.label_en : option.label_fr}
                            </MenuItem>
                        ))}
                    </TextField>
                </DialogContent>
                {isLoading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spinner animation='border' style={{ color: color.primary }} />
                    </div>
                }
                <DialogActions>
                    <Button onClick={handleCloseDialog}>{t('cancel')}</Button>
                    <Button onClick={handleConfirm}>{t('confirm')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModeratorCard